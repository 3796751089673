<template>
  <div class="UnlockContent" tcenter>
    <div class="unlock-creator" v-if="creator" dpadding>
      <UserAvatar :user="creator" size="120px" mauto />
      <Spacer num="1" />
      <div class="info">
        <p class="name">{{ creator.name }}</p>
        <Spacer num=".5" />
        <p class="user" strong>@{{ creator.user }}</p>
      </div>
    </div>

    <div class="unlock-content" v-if="creator">
      <div class="UnlockContentMessage" dpadding>
        <div v-html="normalizeText($locale['unlock_content_message'])"></div>
        <Spacer num=".5" />
        <div class="price">
          <p class="price-label">{{ Go.number_format(getPostPrice(post)) }} {{ $locale["credits"] }}</p>
        </div>
      </div>

      <div class="options">
        <div dpadding>
          <LargeButton
            icon="false"
            class="primary-button"
            :label="`<strong>${$locale['unlock']}</trong>`"
            :rightDesc="`<div><strong>${Go.number_format(getPostPrice(post))}</strong> / <small>${$locale['credits']}</small></div>`"
            @click="unlock(creator)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["post", "modal"],
  data: function() {
    return {
      netPrice: null,
    };
  },
  methods: {
    normalizeText: function(text) {
      return text.replaceAll("$creator", `<b>@${this.creator.user}</b>`);
    },
    unlock: function(cb) {
      if (!this.$user || !this.$user.user) {
        this.$store.dispatch("payload", { after: "unlock", post: this.post._id, creator: this.post.user.user });
        this.modal.close(() => {
          this.UserAdminMenu();
        });
        return;
      }

      this.view({
        title: this.$locale["loading"],
        header: false,
        component: () => import("@/components/widgets/Loader.vue"),
        close: false,
        class: "__loader",
        open: ({ modal }) => {
          this.loader = modal;
          this.unlockNow();
        },
      });
    },
    unlockNow: async function() {
      this.isLoading(true);
      this.res = {};
      const self = this;

      await Go.sleep(APP_TRANSITION_TIME);

      try {
        this.body = { creator: this.creator._id, post: this.post._id };
        this.req = await this.$api.post(`${this.$apiHost}/posts/unlock`, this.body);
        this.res = this.req.data;
      } catch (error) {
        this.res = error;
      }

      this.isLoading(false);

      this.loader.close(() => {
        if (this.res.success) {
          Go.replaceVars(this.res.message, { creator: this.creator.user });
          this.modal.close(() => {
            this.$store.dispatch("getProfile");
            this.showMessage(this.res);
            this.$router.push(`?post=${this.post._id}`).catch(() => {});
          });
          return;
        }

        if (this.res.reason === 1) {
          self.view({
            header: false,
            class: "clean menu",
            animation: "topIn",
            closeOutside: true,
            component: () => import("@/components/credits/CreditsCard.vue"),
            open: async function() {
              self.showMessage(self.res);
            },
          });
          return;
        }

        this.showMessage(this.res);
      });
    },
  },
  computed: {
    creator: function() {
      return this.post.user;
    },
  },
};
</script>
